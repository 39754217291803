<template>
  <div style="width: 100%;">
    <div class="d-flex flex-column">
      <h3>Imager</h3>
      <h1>{{this.instruction}}</h1>
      <span>
        <v-progress-circular
          indeterminate
          color="green"
          v-if="loading"
      ></v-progress-circular>
      </span>
      <span v-if="product">
        <v-card outlined>
          <v-card-text>
            <span class="d-flex flex-row align-start justify-space-between">
              <span>
                <h3>{{product.name}}</h3>
                ID: {{this.product.id}} |  SKU: {{this.product.sku}}
                <span v-for="(image, index) in this.product.metadata.media" :key="index">
                  <img :src="image.url" style="width: 80%;" alt="">
                </span>
              </span>
              <v-btn @click="removeProduct" color="error" fab x-small><v-icon>mdi-close</v-icon></v-btn>
            </span>
          </v-card-text>
        </v-card>
        <span>
          <span class="d-flex flex-row align-center">
            <v-file-input
              accept="image/*"
              label="File input"
              outlined
              dense
              class="mt-4"
              prepend-icon="mdi-camera"
              multiple
              v-model="images"
              @change="previewImages"
            ></v-file-input>
            <v-btn class="mb-3 ml-2" v-if="images" v-show="images.length>0" @click="clearImages" color="error" x-small fab><v-icon>mdi-close</v-icon></v-btn>
          </span>
          <v-btn v-if="images.length>0" block large color="info" class="mb-4" :loading="uploading" @click="addPhotoToProduct">Upload</v-btn>
          <span class="d-flex flex-column" style="width: 100%;" v-if="!uploading">
            <div v-for="(url, index) in urls" :key="index">
              <span class="d-flex flex-column mb-3">
                <img style="border-radius: 7px; width:100%" :src="url">
                <v-btn @click="removeImage(index)" color="error" x-small ><v-icon>mdi-close</v-icon></v-btn>
              </span>
            </div>
          </span>
        </span>
        <!-- <span class="d-flex flex-column justify-center align-center">
          <span>
            <h1>Camera Area</h1>
            <span v-if="imagerFallback">
              <v-file-input
                accept="image/*"
                label="File input"
                outlined
              ></v-file-input>
            </span>
            <v-card-text>
              <span v-if="imgObj.flash" style="width: 10px; height: 10px; border-radius: 10px; background-color: red;"></span>
              <video v-show="!imgObj.isPhotoTaken" autoplay="true" ref="camera" style="width: 350px;" id="videoElement"></video>
              <canvas v-show="imgObj.isPhotoTaken" id="photoTaken" ref="canvas" width="350px" height="262.50px"></canvas>
            </v-card-text>
          </span>
          <span class="d-flex flex-column justify-center align-center">
            <v-btn fab v-if="!imgObj.isPhotoTaken" x-large color="success" @click="takePhoto()"><v-icon>mdi-camera</v-icon></v-btn>
            <v-btn block v-if="imgObj.isPhotoTaken" color="warning" @click="retryPhoto()">Retry</v-btn>
            <v-btn block v-if="imgObj.isPhotoTaken" color="success" :loading="imgObj.addPhotoLoader" @click="addPhotoToJob()">Add Photo to Job</v-btn>
          </span>
        </span> -->
      </span>
    </div>
    <v-snackbar style="margin-bottom: 60px;" v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import scanSystem from '../../plugins/scanSystem';
  import {mapGetters, mapMutations} from "vuex";

  export default {
    data () {
      return {
        images: [],
        urls: [],
        uploading: false,
        instruction: "",
        deleteDialog: false,
        deleteConfirmed: true,
        loading: true,
        product: undefined,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        imgObj: {
          isPhotoTaken: false,
          flash: false,
          addPhotoLoader: false,
          snapAndSaveLoader: false,
          photoToDisplay: '',
        },
        imagerFallback: false,
        steps: ["1. Scan a Barcode", "2. Snap an Image", "3. Confirm Image", "4. Uploading Image", "5. Add another image?"]
      }
    },
    async mounted(){
      this.instruction = this.steps[0]
      this.loading = true
      // this.product = await this.lookupProduct('810036021012', 'SKU')
      // this.instruction = this.steps[1]
      // this.loading = false
    },
    watch: {
      scanBus: {
        handler(){
          console.log("Order.vue: ScanBus Changed")
          if(!this.scanBus.data || this.scanBus.data == "" || this.scanBus.data == undefined){
            console.log("Watcher detected that scanbus was reset. Terminating handoff to handler.")
            return
          }
          if(this.scanBus.handler == 'imager'){
            this.imagerHandler()
          }
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed', 'scanBus',])
    },
    methods: {
      ...mapMutations([
        'setScanBus',
        'resetScanBus',
      ]),
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      previewImages(){
        for(let image of this.images){
          this.urls.push(URL.createObjectURL(image))
        }
      },
      clearImages(){
        this.images = []
        this.urls = []
      },
      removeImage(x){
        this.images.splice(x,1)
        this.urls.splice(x,1)
      },
      async imagerHandler(){
        try {
          console.log("Imager Handler Fired")
          let type = scanSystem.validateType(this.scanBus.data)
          let s = this.scanBus.data.replace(/\\\/\/\\=/,'')
          let id = (s.replace(`${type}-`, ''));
          if(type!='INV'&&type!='DN'&&type!='PO'&&type!='VSID'){
            console.log("Lets add this product to the imager: ", id)
            this.product = await this.lookupProduct(id, type)
            if(!this.product){
              console.log("Product could not be found at this time.")
              return
            }else{
              this.loading = false
              this.instruction = this.steps[1]
              // this.openCamera()
            }
          }
        } catch (error) {
          console.log(error)
          this.snack(error)
        } finally {
          this.setScanBus({data: ""})
        }
      },
      async lookupProduct(s, type){
        try {
          let res;
          if(type === "SKU")
            res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
          if(type === "PL")
            res = await axios.get(`${this.getEndpoint}/api/products/${s}`)
          if(!res.data) throw "Product could not be found"
          if(res.data.error) throw res.data.error
          if(!res.data.data) throw 'External barcode is not in VIMS DB.'
          return res.data.data
        } catch (error) {
          console.error(error)
          this.snack(error, "error")
        }
      },
      removeProduct(){
        this.instruction = this.steps[0]
        this.product = undefined
        this.loading = true
      },
      // takePhoto(){
      //   this.imgObj.flash = true
      //   setTimeout(() => {
      //       this.imgObj.flash = false
      //     }, 50);
      //   this.imgObj.isPhotoTaken = true
      //   const context = this.$refs.canvas.getContext('2d');
      //   context.drawImage(this.$refs.camera, 0, 0, 350,262.50);
      // },
      // retryPhoto(){
      //   this.imgObj.isPhotoTaken = false
      // },
      async addPhotoToProduct(){
        try {
          this.uploading = true
          let data = new FormData()
          this.images.forEach(img=>data.append('file', img))
          let res = await axios.post(`${this.getEndpoint}/api/products/uploadImages/${this.product.id}`, data, {
            header : {
              'Content-Type' : 'multipart/form-data'
            }
          })
          if(res.data.error) throw res.data.error
        } catch (error) {
          console.log(error)
          this.snack(error.msg||error)
        } finally {
          this.uploading = false
        }
      },
      async deletePhoto(img){
        try {
          if(!img) throw "Image not specified."
          let res = await axios.delete(`${this.getEndpoint}/api/jobs/phosdfsdto/${this.$route.query.selected}`, {data:{url:img}})
          if(res.data.error) throw res.data.error
          this.selectedRow.metadata.media = res.data.data.reverse()
          if(this.selectedRow.metadata.media.length<1) this.imgObj.photoToDisplay = null
          this.snack("Image Deleted from Server.")
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error)
        }
      },
      setPhotoToDisplay(img){
        this.imgObj.photoToDisplay = img
      },  
    }
  }
</script>
